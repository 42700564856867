/* ######## */
/* Number 1 */
/* ######## */

.CatArmFrame1 {
    scale: 110%;
    position: absolute;
    height: 350px;
    left: 125px;
    top: 80px;
    transform: translate(-50%, -50%) rotate(10deg);
  }

  .LaserFrame1 {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 90px;
    transform: translate(-50%, -50%) rotate(60deg);
  }

  .LaserLine1 {
    position: absolute;
    height: 350px;
    bottom: -180px;
    left: 150px;
    transform: translate(-50%, -50%) rotate(60deg);
  }

  .LaserDot1 {
    position: absolute;
    width: 30px;
    left: 0px;
    bottom: 70px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Number 2 */
  /* ######## */

  .CatArmFrame2 {
    scale: 110%;
    position: absolute;
    height: 350px;
    left: 145px;
    top: 80px;
    transform: translate(-50%, -50%) rotate(15deg);
  }

  .LaserFrame2 {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 90px;
    transform: translate(-50%, -50%) rotate(55deg);
  }

  .LaserLine2 {
    position: absolute;
    height: 300px;
    bottom: -130px;
    left: 165px;
    transform: translate(-50%, -50%) rotate(55deg);
  }

  .LaserDot2 {
    position: absolute;
    width: 30px;
    left: 45px;
    bottom: 70px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Number 3 */
  /* ######## */

  .CatArmFrame3 {
    scale: 110%;
    position: absolute;
    height: 350px;
    left: 170px;
    top: 80px;
    transform: translate(-50%, -50%) rotate(20deg);
  }

  .LaserFrame3 {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 90px;
    transform: translate(-50%, -50%) rotate(49deg);
  }

  .LaserLine3 {
    position: absolute;
    height: 250px;
    width: 300px;
    bottom: -85px;
    left: 183px;
    transform: translate(-50%, -50%) rotate(49deg);
  }

  .LaserDot3 {
    position: absolute;
    width: 30px;
    left: 90px;
    bottom: 70px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Number 4 */
  /* ######## */

  .CatArmFrame4 {
    scale: 110%;
    position: absolute;
    height: 350px;
    left: 200px;
    top: 90px;
    transform: translate(-50%, -50%) rotate(27deg);
  }

  .LaserFrame4 {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 90px;
    transform: translate(-50%, -50%) rotate(40deg);
  }

  .LaserLine4 {
    position: absolute;
    height: 200px;
    width: 300px;
    bottom: -37px;
    left: 205px;
    transform: translate(-50%, -50%) rotate(40deg);
  }

  .LaserDot4 {
    position: absolute;
    width: 30px;
    left: 140px;
    bottom: 70px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Number 5 */
  /* ######## */

  .CatArmFrame5 {
    scale: 100%;
    position: absolute;
    height: 350px;
    left: 200px;
    top: 70px;
    transform: translate(-50%, -50%) rotate(35deg);
  }

  .LaserFrame5 {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 90px;
    transform: translate(-50%, -50%) rotate(25deg);
  }

  .LaserLine5 {
    position: absolute;
    height: 200px;
    width: 300px;
    bottom: -20px;
    left: 240px;
    transform: translate(-50%, -50%) rotate(25deg);
  }

  .LaserDot5 {
    position: absolute;
    width: 30px;
    left: 190px;
    bottom: 70px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Number 6 */
  /* ######## */

  .CatArmFrame6 {
    scale: 100%;
    position: absolute;
    height: 350px;
    left: 240px;
    top: 100px;
    transform: translate(-50%, -50%) rotate(-50deg);
  }

  .LaserFrame6 {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(10deg);
  }

  .LaserLine6 {
    position: absolute;
    height: 150px;
    width: 300px;
    bottom: 20px;
    left: 255px;
    transform: translate(-50%, -50%) rotate(10deg);
  }

  .LaserDot6 {
    position: absolute;
    width: 30px;
    left: 230px;
    bottom: 70px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Number 7 */
  /* ######## */

  .CatArmFrame7 {
    position: absolute;
    height: 350px;
    left: 270px;
    top: 100px;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .LaserFrame7 {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-8deg);
  }

  .LaserLine7 {
    position: absolute;
    height: 150px;
    width: 300px;
    bottom: 22px;
    left: 282px;
    transform: translate(-50%, -50%) rotate(-8deg);
  }

  .LaserDot7 {
    position: absolute;
    width: 30px;
    left: 280px;
    bottom: 70px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Number 8 */
  /* ######## */

  .CatArmFrame8 {
    scale: 100%;
    position: absolute;
    height: 350px;
    left: 290px;
    top: 100px;
    transform: translate(-50%, -50%) rotate(-37deg);
  }

  .LaserFrame8 {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-25deg);
  }

  .LaserLine8 {
    position: absolute;
    height: 120px;
    width: 300px;
    bottom: 35px;
    left: 315px;
    transform: translate(-50%, -50%) rotate(-25deg);
  }

  .LaserDot8 {
    position: absolute;
    width: 30px;
    left: 325px;
    bottom: 70px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Number 9 */
  /* ######## */

  .CatArmFrame9 {
    scale: 100%;
    position: absolute;
    height: 350px;
    left: 310px;
    top: 90px;
    transform: translate(-50%, -50%) rotate(-30deg);
  }

  .LaserFrame9 {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-38deg);
  }

  .LaserLine9 {
    position: absolute;
    height: 180px;
    width: 300px;
    bottom: -10px;
    left: 330px;
    transform: translate(-50%, -50%) rotate(-38deg);
  }

  .LaserDot9 {
    position: absolute;
    width: 30px;
    left: 370px;
    bottom: 70px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Number 0 */
  /* ######## */

  .CatArmFrame0 {
    scale: 100%;
    position: absolute;
    height: 350px;
    left: 335px;
    top: 90px;
    transform: translate(-50%, -50%) rotate(-25deg);
  }

  .LaserFrame0 {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 90px;
    transform: translate(-50%, -50%) rotate(-50deg);
  }

  .LaserLine0 {
    position: absolute;
    height: 250px;
    bottom: -75px;
    left: 340px;
    transform: translate(-50%, -50%) rotate(-50deg);
  }

  .LaserDot0 {
    position: absolute;
    width: 30px;
    left: 420px;
    bottom: 65px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ########### */
  /* Character - */
  /* ########### */

  .CatArmFrameMinus {
    position: absolute;
    height: 390px;
    right: 20px;
    bottom: -290px;
    transform: rotate(-15deg);
  }

  .LaserFrameMinus {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 90px;
    transform: translate(-50%, -50%) rotate(-57deg);
  }

  .LaserLineMinus {
    position: absolute;
    height: 280px;
    bottom: -103px;
    left: 360px;
    transform: translate(-50%, -50%) rotate(-57deg);
  }

  .LaserDotMinus {
    position: absolute;
    width: 30px;
    left: 460px;
    bottom: 70px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ########### */
  /* Character = */
  /* ########### */

  .CatArmFrameEqual {
    position: absolute;
    height: 390px;
    right: -10px;
    bottom: -270px;
    transform: rotate(-10deg);
  }

  .LaserFrameEqual {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 90px;
    transform: translate(-50%, -50%) rotate(-62deg);
  }

  .LaserLineEqual {
    position: absolute;
    height: 280px;
    bottom: -110px;
    left: 390px;
    transform: translate(-50%, -50%) rotate(-62deg);
  }

  .LaserDotEqual {
    position: absolute;
    width: 30px;
    left: 500px;
    bottom: 70px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /****************************/
  /* End of Row 4 Of Keyboard */
  /* Start Row 3 Of Keyboard  */
  /****************************/

  /* ######## */
  /* Letter Q */
  /* ######## */

  .CatArmFrameQ {
    scale: 200%;
    position: absolute;
    max-width: 170px;
    left: 30px;
    bottom: -160px;
    transform: rotate(5deg);
  }

  .LaserFrameQ {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(49deg);
  }

  .LaserLineQ {
    position: absolute;
    height: 300px;
    width: 300px;
    bottom: -160px;
    left: 140px;
    transform: translate(-50%, -50%) rotate(49deg);
  }

  .LaserDotQ {
    position: absolute;
    width: 30px;
    left: 25px;
    bottom: 25px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Letter W */
  /* ######## */

  .CatArmFrameW {
    scale: 200%;
    position: absolute;
    max-width: 170px;
    left: 60px;
    bottom: -170px;
    transform: rotate(15deg);
  }

  .LaserFrameW {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(44deg);
  }

  .LaserLineW {
    position: absolute;
    height: 300px;
    width: 300px;
    bottom: -150px;
    left: 175px;
    transform: translate(-50%, -50%) rotate(44deg);
  }

  .LaserDotW {
    position: absolute;
    width: 30px;
    left: 70px;
    bottom: 25px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Letter E */
  /* ######## */

  .CatArmFrameE {
    scale: 110%;
    position: absolute;
    height: 300px;
    left: 180px;
    top: 100px;
    transform: translate(-50%, -50%) rotate(25deg);
  }

  .LaserFrameE {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(35deg);
  }

  .LaserLineE {
    position: absolute;
    height: 250px;
    width: 300px;
    bottom: -110px;
    left: 195px;
    transform: translate(-50%, -50%) rotate(35deg);
  }

  .LaserDotE {
    position: absolute;
    width: 30px;
    left: 120px;
    bottom: 20px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Letter R */
  /* ######## */

  .CatArmFrameR {
    position: absolute;
    height: 300px;
    left: 190px;
    top: 80px;
    transform: translate(-50%, -50%) rotate(32deg);
  }

  .LaserFrameR {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(25deg);
  }

  .LaserLineR {
    position: absolute;
    height: 230px;
    width: 300px;
    bottom: -80px;
    left: 225px;
    transform: translate(-50%, -50%) rotate(25deg);
  }

  .LaserDotR {
    position: absolute;
    width: 30px;
    left: 170px;
    bottom: 25px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Letter T */
  /* ######## */

  .CatArmFrameT {
    position: absolute;
    height: 300px;
    left: 215px;
    top: 90px;
    transform: translate(-50%, -50%) rotate(42deg);
  }

  .LaserFrameT {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(13deg);
  }

  .LaserLineT {
    position: absolute;
    height: 250px;
    width: 300px;
    bottom: -80px;
    left: 252px;
    transform: translate(-50%, -50%) rotate(13deg);
  }

  .LaserDotT {
    position: absolute;
    width: 30px;
    left: 215px;
    bottom: 25px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Letter Y */
  /* ######## */

  .CatArmFrameY {
    position: absolute;
    height: 300px;
    left: 270px;
    top: 80px;
    transform: translate(-50%, -50%) rotate(-51deg);
  }

  .LaserFrameY {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(2deg);
  }

  .LaserLineY {
    position: absolute;
    height: 230px;
    width: 300px;
    bottom: -70px;
    left: 270px;
    transform: translate(-50%, -50%) rotate(2deg);
  }

  .LaserDotY {
    position: absolute;
    width: 30px;
    left: 255px;
    bottom: 25px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Letter U */
  /* ######## */

  .CatArmFrameU {
    position: absolute;
    height: 300px;
    left: 295px;
    top: 85px;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .LaserFrameU {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-10deg);
  }

  .LaserLineU {
    position: absolute;
    height: 200px;
    width: 300px;
    bottom: -50px;
    left: 290px;
    transform: translate(-50%, -50%) rotate(-10deg);
  }

  .LaserDotU {
    position: absolute;
    width: 30px;
    left: 295px;
    bottom: 25px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Letter I */
  /* ######## */

  .CatArmFrameI {
    position: absolute;
    height: 300px;
    left: 325px;
    top: 80px;
    transform: translate(-50%, -50%) rotate(-35deg);
  }

  .LaserFrameI {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-25deg);
  }

  .LaserLineI {
    position: absolute;
    height: 200px;
    width: 300px;
    bottom: -53px;
    left: 317px;
    transform: translate(-50%, -50%) rotate(-25deg);
  }

  .LaserDotI {
    position: absolute;
    width: 30px;
    left: 345px;
    bottom: 25px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Letter O */
  /* ######## */

  .CatArmFrameO {
    scale: 120%;
    position: absolute;
    height: 300px;
    left: 370px;
    top: 130px;
    transform: translate(-50%, -50%) rotate(-25deg);
  }

  .LaserFrameO {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-35deg);
  }

  .LaserLineO {
    position: absolute;
    height: 200px;
    width: 300px;
    bottom: -65px;
    left: 350px;
    transform: translate(-50%, -50%) rotate(-35deg);
  }

  .LaserDotO {
    position: absolute;
    width: 30px;
    left: 390px;
    bottom: 23px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Letter P */
  /* ######## */

  .CatArmFrameP {
    scale: 120%;
    position: absolute;
    height: 300px;
    left: 400px;
    top: 120px;
    transform: translate(-50%, -50%) rotate(-18deg);
  }

  .LaserFrameP {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-43deg);
  }

  .LaserLineP {
    position: absolute;
    height: 280px;
    width: 300px;
    bottom: -125px;
    left: 357px;
    transform: translate(-50%, -50%) rotate(-43deg);
  }

  .LaserDotP {
    position: absolute;
    width: 30px;
    left: 435px;
    bottom: 23px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ########### */
  /* Character [ */
  /* ########### */

  .CatArmFrameOpenBracket {
    scale: 120%;
    position: absolute;
    height: 300px;
    left: 425px;
    top: 110px;
    transform: translate(-50%, -50%) rotate(-10deg);
  }

  .LaserFrameOpenBracket {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-50deg);
  }

  .LaserLineOpenBracket {
    position: absolute;
    height: 250px;
    width: 300px;
    bottom: -115px;
    left: 400px;
    transform: translate(-50%, -50%) rotate(-50deg);
  }

  .LaserDotOpenBracket {
    position: absolute;
    width: 30px;
    left: 480px;
    bottom: 23px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ########### */
  /* Character ] */
  /* ########### */

  .CatArmFrameCloseBracket {
    scale: 130%;
    position: absolute;
    height: 300px;
    left: 460px;
    top: 130px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  .LaserFrameCloseBracket {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-55deg);
  }

  .LaserLineCloseBracket {
    position: absolute;
    height: 300px;
    width: 300px;
    bottom: -160px;
    left: 420px;
    transform: translate(-50%, -50%) rotate(-55deg);
  }

  .LaserDotCloseBracket {
    position: absolute;
    width: 30px;
    left: 520px;
    bottom: 23px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ########### */
  /* Character \ */
  /* ########### */

  .CatArmFrameBackslash {
    scale: 130%;
    position: absolute;
    height: 300px;
    left: 450px;
    top: 130px;
    transform: translate(-50%, -50%) rotate(5deg);
  }

  .LaserFrameBackslash {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-60deg);
  }

  .LaserLineBackslash {
    position: absolute;
    height: 350px;
    width: 300px;
    bottom: -210px;
    left: 450px;
    transform: translate(-50%, -50%) rotate(-60deg);
  }

  .LaserDotBackslash {
    position: absolute;
    width: 30px;
    left: 580px;
    bottom: 23px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Letter A */
  /* ######## */

  .CatArmFrameA {
    scale: 100%;
    position: absolute;
    height: 300px;
    left: 120px;
    top: 100px;
    transform: translate(-50%, -50%) rotate(5deg);
  }

  .LaserFrameA {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(42deg);
  }

  .LaserLineA {
    position: absolute;
    height: 330px;
    bottom: -215px;
    left: 150px;
    transform: translate(-50%, -50%) rotate(42deg);
  }

  .LaserDotA {
    position: absolute;
    width: 30px;
    left: 40px;
    bottom: -20px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Letter S */
  /* ######## */

  .CatArmFrameS {
    position: absolute;
    height: 300px;
    left: 140px;
    top: 110px;
    transform: translate(-50%, -50%) rotate(15deg);
  }

  .LaserFrameS {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(37deg);
  }

  .LaserLineS {
    position: absolute;
    height: 300px;
    bottom: -185px;
    left: 170px;
    transform: translate(-50%, -50%) rotate(37deg);
  }

  .LaserDotS {
    position: absolute;
    height: 30px;
    bottom: -20px;
    left: 80px;
    transform: translate(-50%, -50%) rotate(42deg);
  }

  /* ######## */
  /* Letter D */
  /* ######## */

  .CatArmFrameD {
    position: absolute;
    height: 300px;
    left: 170px;
    top: 120px;
    transform: translate(-50%, -50%) rotate(25deg);
  }

  .LaserFrameD {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(29deg);
  }

  .LaserLineD {
    position: absolute;
    height: 300px;
    bottom: -180px;
    left: 200px;
    transform: translate(-50%, -50%) rotate(29deg);
  }

  .LaserDotD {
    position: absolute;
    height: 30px;
    bottom: -23px;
    left: 125px;
    transform: translate(-50%, -50%) rotate(42deg);
  }

  /* ######## */
  /* Letter F */
  /* ######## */

  .CatArmFrameF {
    position: absolute;
    height: 300px;
    left: 195px;
    top: 130px;
    transform: translate(-50%, -50%) rotate(35deg);
  }

  .LaserFrameF {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(20deg);
  }

  .LaserLineF {
    position: absolute;
    height: 300px;
    bottom: -160px;
    left: 230px;
    transform: translate(-50%, -50%) rotate(20deg);
  }

  .LaserDotF {
    position: absolute;
    width: 30px;
    left: 170px;
    bottom: -20px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Letter G */
  /* ######## */

  .CatArmFrameG {
    scale: 100%;
    position: absolute;
    height: 300px;
    left: 210px;
    top: 110px;
    transform: translate(-50%, -50%) rotate(40deg);
  }

  .LaserFrameG {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(10deg);
  }

  .LaserLineG {
    position: absolute;
    height: 250px;
    width: 300px;
    bottom: -130px;
    left: 250px;
    transform: translate(-50%, -50%) rotate(10deg);
  }

  .LaserDotG {
    position: absolute;
    width: 30px;
    left: 220px;
    bottom: -20px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Letter H */
  /* ######## */

  .CatArmFrameH {
    scale: 100%;
    position: absolute;
    height: 300px;
    left: 280px;
    top: 120px;
    transform: translate(-50%, -50%) rotate(-50deg);
  }

  .LaserFrameH {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-2deg);
  }

  .LaserLineH {
    position: absolute;
    height: 250px;
    bottom: -120px;
    left: 270px;
    transform: translate(-50%, -50%) rotate(-2deg);
  }

  .LaserDotH {
    position: absolute;
    width: 30px;
    left: 265px;
    bottom: -20px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }


  /* ######## */
  /* Letter J */
  /* ######## */

  .CatArmFrameJ {
    scale: 90%;
    position: absolute;
    height: 300px;
    left: 270px;
    top: 110px;
    transform: translate(-50%, -50%) rotate(-42deg);
  }

  .LaserFrameJ {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-10deg);
  }

  .LaserLineJ {
    position: absolute;
    height: 250px;
    width: 300px;
    bottom: -130px;
    left: 295px;
    transform: translate(-50%, -50%) rotate(-10deg);
  }

  .LaserDotJ {
    position: absolute;
    width: 30px;
    left: 305px;
    bottom: -25px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Letter K */
  /* ######## */

  .CatArmFrameK {
    scale: 90%;
    position: absolute;
    height: 300px;
    left: 310px;
    top: 100px;
    transform: translate(-50%, -50%) rotate(-32deg);
  }

  .LaserFrameK {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-22deg);
  }

  .LaserLineK {
    position: absolute;
    height: 300px;
    bottom: -160px;
    left: 315px;
    transform: translate(-50%, -50%) rotate(-22deg);
  }

  .LaserDotK {
    position: absolute;
    width: 30px;
    left: 355px;
    bottom: -25px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Letter L */
  /* ######## */

  .CatArmFrameL {
    scale: 90%;
    position: absolute;
    height: 300px;
    left: 335px;
    top: 90px;
    transform: translate(-50%, -50%) rotate(-25deg);
  }

  .LaserFrameL {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-30deg);
  }

  .LaserLineL {
    position: absolute;
    height: 300px;
    bottom: -165px;
    left: 340px;
    transform: translate(-50%, -50%) rotate(-30deg);
  }

  .LaserDotL {
    position: absolute;
    width: 30px;
    left: 400px;
    bottom: -23px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ########### */
  /* Character ; */
  /* ########### */

  .CatArmFrameSemicolon {
    scale: 90%;
    position: absolute;
    height: 300px;
    left: 368px;
    top: 80px;
    transform: translate(-50%, -50%) rotate(-10deg);
  }

  .LaserFrameSemicolon {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-39deg);
  }

  .LaserLineSemicolon {
    position: absolute;
    height: 300px;
    bottom: -175px;
    left: 370px;
    transform: translate(-50%, -50%) rotate(-39deg);
  }

  .LaserDotSemicolon {
    position: absolute;
    width: 30px;
    left: 450px;
    bottom: -23px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ########### */
  /* Character ' */
  /* ########### */

  .CatArmFrameQuotation {
    scale: 100%;
    position: absolute;
    height: 300px;
    left: 400px;
    top: 100px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  .LaserFrameQuotation {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .LaserLineQuotation {
    position: absolute;
    height: 350px;
    bottom: -215px;
    left: 386px;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .LaserDotQuotation {
    position: absolute;
    width: 30px;
    left: 490px;
    bottom: -23px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }


  /****************************/
  /* End of Row 2 Of Keyboard */
  /* Start Row 1 Of Keyboard  */
  /****************************/

  /* ######## */
  /* Letter Z */
  /* ######## */

  .CatArmFrameZ {
    scale: 110%;
    position: absolute;
    height: 250px;
    left: 130px;
    top: 130px;
    transform: translate(-50%, -50%) rotate(7deg);
  }

  .LaserFrameZ {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(35deg);
  }

  .LaserLineZ {
    position: absolute;
    height: 350px;
    bottom: -265px;
    left: 160px;
    transform: translate(-50%, -50%) rotate(35deg);
  }

  .LaserDotZ {
    position: absolute;
    width: 30px;
    left: 60px;
    bottom: -67px;
    transform: translate(-50%, -50%);
  }

  /* ######## */
  /* Letter X */
  /* ######## */

  .CatArmFrameX {
    position: absolute;
    height: 250px;
    left: 145px;
    top: 125px;
    transform: translate(-50%, -50%) rotate(10deg);
  }

  .LaserFrameX {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(27deg);
  }

  .LaserLineX {
    position: absolute;
    height: 350px;
    bottom: -250px;
    left: 195px;
    transform: translate(-50%, -50%) rotate(27deg);
  }

  .LaserDotX {
    position: absolute;
    width: 30px;
    left: 110px;
    bottom: -67px;
    transform: translate(-50%, -50%);
  }

  /* ######## */
  /* Letter C */
  /* ######## */

  .CatArmFrameC {
    position: absolute;
    height: 250px;
    left: 180px;
    top: 135px;
    transform: translate(-50%, -50%) rotate(27deg);
  }

  .LaserFrameC {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(20deg);
  }

  .LaserLineC {
    position: absolute;
    height: 300px;
    bottom: -205px;
    left: 213px;
    transform: translate(-50%, -50%) rotate(20deg);
  }

  .LaserDotC {
    position: absolute;
    width: 30px;
    left: 155px;
    bottom: -65px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Letter V */
  /* ######## */

  .CatArmFrameV {
    position: absolute;
    height: 250px;
    left: 200px;
    top: 135px;
    transform: translate(-50%, -50%) rotate(40deg);
  }

  .LaserFrameV {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(11deg);
  }

  .LaserLineV {
    position: absolute;
    height: 340px;
    bottom: -224px;
    left: 243px;
    transform: translate(-50%, -50%) rotate(11deg);
  }

  .LaserDotV {
    position: absolute;
    width: 30px;
    left: 200px;
    bottom: -67px;
    transform: translate(-50%, -50%);
  }

  /* ######## */
  /* Letter B */
  /* ######## */

  .CatArmFrameB {
    position: absolute;
    height: 250px;
    left: 220px;
    top: 135px;
    transform: translate(-50%, -50%) rotate(50deg);
  }

  .LaserFrameB {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(2deg);
  }

  .LaserLineB {
    position: absolute;
    height: 320px;
    bottom: -210px;
    left: 265px;
    transform: translate(-50%, -50%) rotate(2deg);
  }

  .LaserDotB {
    position: absolute;
    width: 30px;
    left: 248px;
    bottom: -67px;
    transform: translate(-50%, -50%);
  }

  /* ######## */
  /* Letter N */
  /* ######## */

  .CatArmFrameN {
    scale: 130%;
    position: absolute;
    max-width: 165px;
    left: 210px;
    bottom: -210px;
    transform: rotate(-50deg);
  }

  .LaserFrameN {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  .LaserLineN {
    position: absolute;
    height: 300px;
    bottom: -200px;
    left: 287px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  .LaserDotN {
    position: absolute;
    width: 30px;
    left: 290px;
    bottom: -70px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ######## */
  /* Letter M */
  /* ######## */

  .CatArmFrameM {
    scale: 130%;
    position: absolute;
    max-width: 165px;
    left: 230px;
    bottom: -210px;
    transform: rotate(-30deg);
  }

  .LaserFrameM {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-14deg);
  }

  .LaserLineM {
    position: absolute;
    height: 300px;
    bottom: -200px;
    left: 310px;
    transform: translate(-50%, -50%) rotate(-14deg);
  }

  .LaserDotM {
    position: absolute;
    width: 30px;
    left: 335px;
    bottom: -70px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ########### */
  /* Character , */
  /* ########### */

  .CatArmFrameComma {
    scale: 120%;
    position: absolute;
    max-width: 165px;
    left: 263px;
    bottom: -200px;
    transform: rotate(-22deg);
  }

  .LaserFrameComma {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-22deg);
  }

  .LaserLineComma {
    position: absolute;
    height: 320px;
    bottom: -220px;
    left: 335px;
    transform: translate(-50%, -50%) rotate(-22deg);
  }

  .LaserDotComma {
    position: absolute;
    width: 30px;
    left: 380px;
    bottom: -70px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ########### */
  /* Character . */
  /* ########### */

  .CatArmFramePeriod {
    scale: 130%;
    position: absolute;
    max-width: 165px;
    left: 290px;
    bottom: -200px;
    transform: rotate(-10deg);
  }

  .LaserFramePeriod {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-30deg);
  }

  .LaserLinePeriod {
    position: absolute;
    height: 320px;
    bottom: -225px;
    left: 362px;
    transform: translate(-50%, -50%) rotate(-30deg);
  }

  .LaserDotPeriod {
    position: absolute;
    width: 30px;
    left: 425px;
    bottom: -70px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ########### */
  /* Character / */
  /* ########### */

  .CatArmFrameSlash {
    scale: 140%;
    position: absolute;
    max-width: 165px;
    left: 312px;
    bottom: -195px;
    transform: rotate(0deg);
  }

  .LaserFrameSlash {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(-37deg);
  }

  .LaserLineSlash {
    position: absolute;
    height: 330px;
    bottom: -240px;
    left: 390px;
    transform: translate(-50%, -50%) rotate(-37deg);
  }

  .LaserDotSlash {
    position: absolute;
    width: 30px;
    left: 470px;
    bottom: -70px;
    transform: translate(-50%, -50%) rotate(-5deg);
  }

  /* ################ */
  /* Character   Left */
  /* ################ */

  .CatArmFrameSpaceLeft {
    scale: 100%;
    position: absolute;
    max-width: 165px;
    left: 20%;
    top: 70%;
    transform: rotate(30deg);
  }

  .LaserFrameSpaceLeft {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(35deg);
  }

  .LaserLineSpaceLeft {
    position: absolute;
    height: 300px;
    bottom: -180px;
    left: 180px;
    transform: translate(-50%, -50%) rotate(35deg);
  }

  /* ################# */
  /* Character   Right */
  /* ################# */

  .CatArmFrameSpaceRight {
    scale: 100%;
    position: absolute;
    max-width: 165px;
    right: 22%;
    top: 77%;
    transform: rotate(-50deg);
  }

  .LaserFrameSpaceRight {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%) rotate(35deg);
  }

  .LaserLineSpaceRight {
    position: absolute;
    height: 300px;
    bottom: -180px;
    left: 180px;
    transform: translate(-50%, -50%) rotate(35deg);
  }

  /*************************/
  /* End Row 1 Of Keyboard */
  /*************************/

  .KeyboardFrame {
    position: absolute;
    width: 700px;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }

  .CatFrame {
    position: absolute;
    width: 300px;
    left: 260px;
    top: 150px;
    transform: translate(-50%, -50%);
  }

  .FlameFrame {
    position: absolute;
    width: 600px;
    left: 260px;
    top: 43px;
    transform: translate(-50%, -50%);
  }

  .CatArmFrameLeft {
    position: absolute;
    height: 200px;
    left: 150px;
    top: 150px;
    transform: translate(-50%, -50%);
  }

  .CatArmFrameRight {
    position: absolute;
    height: 200px;
    left: 350px;
    top: 150px;
    transform: translate(-50%, -50%);
  }

  .LaserLine {
    position: absolute;
    width: 0px;
    left: 0px;
    bottom: 0px;
    transform: translate(-50%, -50%);
  }

  .LaserFrame {
    position: absolute;
    width: 150px;
    left: 280px;
    bottom: 100px;
    transform: translate(-50%, -50%);
  }

  .LaserDot {
    position: absolute;
    width: 0px;
    left: 0px;
    bottom: 0px;
    transform: translate(-50%, -50%);
  }

  .image-container {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 475px;
    margin-top: 200px;
    transform: scale(0.75); /* scale everything down */
    transform-origin: top center;
    width: 50%;
  }