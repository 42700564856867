.Indicator {
    border: black 2px solid;
    padding: 10px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    width: 160px;
    float: right;
    display: flex;
    flex-direction: column;
}

.Indicators {
    float: right;
    display: flex;
    flex-direction: column;
    width: 100%;
}
