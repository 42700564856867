.CatProgressBar {
	margin-top: 110px;
	width: 140%;
	padding-right: 20%;
	float: right;
    display: flex;
    flex-direction: column;
	padding: 10px;
    margin-left: 20px;
    margin-right: 20px;
	margin-bottom: -10px;
}
